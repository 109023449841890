import { API_URL } from 'config'

export const API_ENPOINTS = {
  createLead: `${API_URL}/api/v1/leads/new_lead/`,
  enhanceLead: `${API_URL}/api/v1/leads/new_lead/extrainfo/`,
}

export const FORM_VERSIONS = {
  REQUEST: '1',
  CALLBACK: '2',
  NEWSLETTER: '3',
}
