import React from 'react'

import { Box } from 'components'
import { PhoneLink, EmailLink } from 'components/ContactLink'

export const ErrorMessage = ({ message, ...props }) => (
  <Box
    sx={{
      py: 1,
      mb: 2,
      color: 'red',
      fontSize: 0,
      gridArea: 'errorMessage',
    }}
    {...props}
  >
    {message}
  </Box>
)

export const SubmissionFailedErrorMessage = () => (
  <ErrorMessage
    message={
      <>
       Es gab einen Fehler bei Ihrer Anfrage. Bitte kontaktieren Sie
       uns direkt unter <EmailLink /> oder <PhoneLink />
      </>
    }
  />
)
