import React, { useState } from 'react'

import { FORM_VERSIONS } from 'api-utils'

import { Box } from 'components'

import Form from './Form'
import { Success } from './components'

const Newsletter = ({ title, navigate, onClose }) => {
  const [showSuccess, setShowSuccess] = useState(false)

  return (
    <Box px={4} py={3} pb={6}>
      {showSuccess ? (
        <Success message="Danke für die Anmeldung zum Bambus Newsletter." />
      ) : (
        <Form
          formVersion={FORM_VERSIONS.NEWSLETTER}
          onSuccess={() => setShowSuccess(true)}
          consentMessage="Ich willige ein, dass meine Daten von Bambus für Marketingzwecke gespeichert werden."
        />
      )}
    </Box>
  )
}

export default Newsletter
