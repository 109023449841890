import React from 'react'
import { Send } from 'icons'

import { Text, Button, Icon } from 'components'

export const Submit = (props) => {
  return (
    <Button id="formComplete" width="100%" type="submit" {...props}>
      <Icon source={Send} size={16} mr={2} />
      Absenden
    </Button>
  )
}

export const Success = ({ message }) => {
  return <Text>{message}</Text>
}
