import React, { Children, cloneElement, forwardRef } from 'react'

import {
  Box,
  Text,
  Input as InputBase,
  Textarea as TextareaBase,
  Checkbox as CheckboxBase,
  Select as SelectBase,
  Label as LabelBase,
  Radio as RadioBase,
} from 'components'
import { ErrorMessage } from './Errors'

const StatefulInput = ({ error, children, ...props }) => (
  <>
    {cloneElement(Children.only(children), [{ error }])}
    {error && <ErrorMessage message={error.message} />}
  </>
)

export const Badge = ({ label, noPositioning = false, ...rest }) => (
  <Text
    sx={{
      ...(!noPositioning && {
        position: 'absolute',
        top: 0,
        right: 20,
        transform: 'translateY(-50%)',
      }),
      ...(noPositioning && {
        minWidth: 'unset',
      }),
      px: 1,
      bg: 'colorBlue5',
      borderRadius: 'default',
      fontSize: '12px',
      color: 'muted',
    }}
    {...rest}
  >
    {label}
  </Text>
)

export const Label = (props) => (
  <LabelBase sx={{ cursor: 'pointer' }} {...props} />
)

export const Radio = forwardRef((props, ref) => (
  <RadioBase ref={ref} {...props} />
))

export const Input = forwardRef(
  ({ error, badge, prefix, postfix, ...props }, ref) => {
    return (
      <StatefulInput error={error}>
        <Box
          {...((postfix || prefix) && { variant: 'forms.input' })}
          sx={{
            position: 'relative',
            ...((postfix || prefix) && {
              display: 'flex',
              p: 0,
            }),
          }}
        >
          {badge && <Badge label={badge} />}
          {prefix && (
            <Box
              sx={{
                color: 'colorBlue100',
                flexShrink: 0,
                pointerEvents: 'none',
                p: 2,
                paddingLeft: 3,
              }}
            >
              {prefix}
            </Box>
          )}
          <InputBase
            ref={ref}
            {...(error && { sx: { borderColor: 'red' } })}
            {...((postfix || prefix) && {
              variant: 'blank',
            })}
            {...props}
          />
          {postfix && (
            <Box
              sx={{
                color: 'colorBlue50',
                flexShrink: 0,
                pointerEvents: 'none',
                p: 2,
              }}
            >
              {postfix}
            </Box>
          )}
        </Box>
      </StatefulInput>
    )
  }
)

export const Textarea = forwardRef(({ error, ...props }, ref) => {
  return (
    <StatefulInput error={error}>
      <TextareaBase
        {...props}
        sx={
          error
            ? {
                borderColor: 'red',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : {}
        }
        ref={ref}
      />
    </StatefulInput>
  )
})

export const Select = forwardRef(({ error, options, ...props }, ref) => {
  return (
    <StatefulInput error={error}>
      <SelectBase
        {...props}
        sx={
          error
            ? {
                borderColor: 'red',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : {}
        }
        ref={ref}
      >
        {options &&
          options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
      </SelectBase>
    </StatefulInput>
  )
})

export const Checkbox = forwardRef(
  (
    { label, error, noPaddingY = false, alignItemsCenter = false, ...props },
    ref
  ) => {
    return (
      <StatefulInput error={error}>
        <Label
          {...(!noPaddingY && { py: 2 })}
          {...(alignItemsCenter && { sx: { alignItems: 'center' } })}
        >
          <div style={{ flexShrink: 0 }}>
            <CheckboxBase {...props} ref={ref} />
          </div>
          {label}
        </Label>
      </StatefulInput>
    )
  }
)
