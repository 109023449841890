import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { createLead } from 'api-utils/lead'

import { Box } from 'components'
import { Input, Checkbox, SubmissionFailedErrorMessage } from 'components/Forms'

import { Submit } from './components'

const trackEvents = [
  'contactform_request_complete',
  'contactform_callback_complete',
  'contactform_newsletter_complete',
]

const Form = ({ formVersion, consentMessage, onSuccess }) => {
  const [showError, setShowError] = useState(false)

  const { register, handleSubmit, setError, errors } = useForm()

  const onSubmit = async (formData) => {
    // const telephonePrefix = formData.telephone_prefix
    //delete the telephone_prefix from data as we don't want it spread into the payload
    // delete formData['telephone_prefix']

    const { success, errors } = await createLead({
      trackEvent: trackEvents[formVersion - 1],
      lead: {
        ...formData,
      },
    })

    if (errors) {
      errors.forEach(({ name, type, message }) =>
        setError(name, { type, message })
      )
    }

    if (success) {
      onSuccess()
    } else if (!errors) {
      setShowError(true)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box py={2}>
        <Input
          type="hidden"
          name="form_version"
          value={formVersion}
          ref={register}
        />
        <Input
          placeholder="Vorname"
          name="first_name"
          ref={register({ required: 'Bitte Vornamen angeben' })}
          error={errors.first_name}
        />
      </Box>
      <Box py={2}>
        <Input
          placeholder="Nachname"
          name="last_name"
          ref={register({ required: 'Bitte Nachnamen angeben' })}
          error={errors.last_name}
        />
      </Box>
      <Box py={2}>
        <Input
          placeholder="E-Mail"
          name="email"
          ref={register({ required: 'Bitte E-Mail angeben' })}
          error={errors.email}
        />
      </Box>

      <Box py={2}>
        <Checkbox
          label={consentMessage}
          name="consent_box"
          ref={register({ required: 'Bitte einwilligen' })}
          error={errors.consent_box}
        />
      </Box>

      {showError && <SubmissionFailedErrorMessage />}

      <Submit />
    </form>
  )
}

export default Form
