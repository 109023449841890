import { v4 as uuidv4 } from 'uuid'

import { hydrateFormDataWithContextParams } from 'components/utils'

import { API_ENPOINTS } from 'api-utils'

const postLead = async function (data = {}) {
  const response = await fetch(API_ENPOINTS.createLead, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  const result = await response.json()

  return {
    response: { ok: response.ok, status: response.status },
    result,
  }
}

const getServiceDimensionValue = () => {
  if (window.location.pathname.includes('/beleihen')) {
    return 'equity_release'
  }
  if (window.location.pathname.includes('/finanzieren')) {
    return 'classic_mortgage'
  }
  return 'partial_ownership'
}

export const createLead = async ({ lead, trackEvent, customEventPayload }) => {
  // UUID that gets sent to the backend and to GTM. Can be used to have unique event IDs (helps in data/events deduplication) for various FE & BE analytics
  const externalEventID = uuidv4()

  const { response, result } = await postLead(
    hydrateFormDataWithContextParams({
      ...lead,
      external_event_id: externalEventID,
      ...(!lead.sign_up_service_dimension && {
        sign_up_service_dimension: getServiceDimensionValue(),
      }),
    })
  )

  const r = {
    success: response.ok,
    result,
    ...(response.status === 400 &&
      result && {
        // NOTE:
        // Restructure API response to be compatible with react-hook-form's `setError` API
        // Input: `{ [field]: 'message' }`
        // Output: `[{ type: 'error', name: field, message: message ] }]`
        errors: Object.keys(result).map((key) => ({
          type: 'error',
          name: key,
          message: result[key],
        })),
      }),
  }

  if (response.ok) {
    trackSubmission(trackEvent, customEventPayload, externalEventID)
  }

  return r
}

export const enhanceLeadData = async ({ email, token, extraInfo }) => {
  const response = await fetch(API_ENPOINTS.enhanceLead, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, extrainfo_token: token, ...extraInfo }),
  })

  const result = await response.json()

  return {
    response: { ok: response.ok, status: response.status },
    result,
  }
}

const trackSubmission = (
  eventName = 'generic_cta_complete',
  customEventPayload = {},
  externalEventID
) => {
  eventName &&
    window.dataLayer &&
    window.dataLayer.push({
      event: eventName,
      ...customEventPayload,
    })

  eventName &&
    window._paq &&
    window._paq.push(['trackEvent', eventName, eventName])

  // CTA Complete Event gets triggered for every type of lead creation
  window.dataLayer &&
    window.dataLayer.push({
      event: 'cta_complete',
      ...customEventPayload,
      external_event_id: externalEventID,
    })
  window._paq &&
    window._paq.push([
      'trackEvent',
      'cta_complete',
      'cta_complete',
      'cta_complete',
      customEventPayload.amount || 100000,
    ])
}
